//Vars
$head-height-sm: 68px;
$head-height: 130px;
$subnav-height: 50px;

/*-------------------
	HEADER
-------------------*/
.main-head {
  position: relative;

  .navbar {
    background: #fff;
    border: none;
    min-height: 68px;

    @media (min-width: $screen-sm-min) {
      height: 130px;
    }

    margin: 0;
    z-index: 2;
  }
  .navbar-header {

    position: relative;
    z-index: 50;
    padding-top: 20px;

    @media (min-width: $screen-sm-min) {
      padding-top: 46px;
    }

  }

  .container-header{
    @media (min-width: $screen-sm-min) {
      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100px;
        width: calc( (100% - #{$container-tablet - $grid-gutter-width}) /2);
        background: url("../images/logo-bleed.png") #fff bottom repeat-x;
      }
    }
    @media (min-width: $screen-md-min) {
      &:before {
        width: calc((100% - #{$container-desktop - $grid-gutter-width}) / 2);
      }
    }
  }
  .navbar-logo{
    float: left;
    margin-top: 4px;
  }
  .navbar-sitetitle {
    float: left;
    display: block;
    padding-left: 10px;

    margin-top: 0px;

    @media (min-width: $screen-sm-min) {
      padding-left: 35px;
    }
    .title{
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 0;
      @media (min-width: $screen-sm-min) {
        font-size: 48px;
        margin-top: 0px;
      }

    }
    .slogan{
      display: block;
      font-family: 'avenirdemi';
      font-size: 14px;
      margin-top: -10px;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
  }
  .language-bar-chooser {
    font-size: 14px;
    .on > a, li > a:hover {
      color: $brand-primary;
    }
  }
  .navbar-toggle{
    border: none;
    margin-top: 0;
    .icon-bar{
      background: $brand-primary;
    }

    &:hover{
      background: $bg-grey;
    }
  }
  #navbar-mobile{


    padding: 0;

    @media(min-width: $grid-float-breakpoint){
      display: none !important;

    }

    .navbar-nav {
      display: block;
      float: none;
      vertical-align: top;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;

      text-align: center;



      li{
        position: relative;
        display: block;

        a{
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 20px;

          color: $brand-primary;
          font-size: 16px;
          text-transform: uppercase;
          font-family: 'avenirdemi';
        }
        
        &.select-button{
          background: $brand-success;
          a{
            color: #fff;
          }
        }
      }
    }

  }

}
.navbar-sub{
  margin-bottom: 0;
  border: none;
  background-color: $bg-blue-dark;
  display: none;
  @media(min-width: $grid-float-breakpoint ){
    display: block;
  }

  .navbar-nav{
    width: 100%;
    li{

      border-right: #BCE5F4 2px solid;
      margin-top: 10px;
      margin-bottom: 10px;
      a{

        color: #fff;
        padding-top: 6px;
        padding-bottom: 4px;
        font-size: 16px;
        text-transform: uppercase;
        font-family: 'avenirdemi';

        &:focus{
          background: transparent;
        }
      }
      &:hover, &.active{
        a{
          background-color: transparent;
          color: $brand-primary;
        }
      }

      &:nth-last-child(2){
        border-right: none;
      }

      &.select-button{
        float: right;
        border-right: none;
        background: $brand-success;
        color: #fff;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover, &:focus, &.active{
          background: darken($brand-success, 10%);
          a{
            color: #fff;
          }
        }
      }
    }

  }

}

.nav-sub{

  &.affix{
    width: 100%;
    top:0;
    z-index: 30;
  }
}

