/*-------------------
	FORM
-------------------*/
label{
  font-size: 16px;
}
.form-read {
  font-family: 'avenirdemi', sans-serif;
  border: none;
  background: none;
  background-color: transparent;
  font-size: 22px;
  color: #0f204b;
}
.form-inline .form-group {
  width: 100%;
}
.form-control,
.form-inline .form-control {
  background:#fff;
  border:1px solid $brand-primary;
  border-radius:4px;
  height:50px;
  font-size: 16px;
}
.form-control:focus {
  box-shadow: none;
}
.controlbar .form-group {
  margin-right: 15px
}
.controlbar .form-group:last-child {
  margin-right: 0;
  float: right;
}

select.form-control{
  text-indent: 0.01px;
  text-overflow: '';
  position: relative;
  padding-right: 34px;

}

form {
  input[type='checkbox']:checked,
  input[type='checkbox']:not(:checked),
  input[type='radio']:checked,
  input[type='radio']:not(:checked) {
    background: transparent;
    position: relative;
    visibility: hidden;
    display: none;
    margin: 0;
    padding: 0;
  }
  input[type='checkbox'] + label:hover::before,
  input[type='radio'] + label:hover::before{
    background:#009fd9;
    color:#009fd9;
  }

  input[type='checkbox'] + label::before,
  input[type='radio'] + label::before {

    font-family: "FontAwesome", sans-serif;
    text-align: center;
    line-height: 13px;
    font-size: 11px;
    font-weight: normal;
    content: "\f00c";

    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-bottom: 0;
    padding-left: 0px;
    top: 3px;
    position: relative;
    border: 1px solid #009fd9;
    border-radius: 2px;
    background: #f2f2f2;
    color: #f2f2f2;

    cursor: pointer;
    transition: background-color 0.25s ease, color 0.25s ease;
  }
  input[type='checkbox']:checked + label::before {
    content: "\f00c";
    font-family: "FontAwesome", sans-serif;
    color: #fff;
    background:#009fd9;
  }
  .checkbox label{
    padding-left: 0;
  }
}


@import "selectric";