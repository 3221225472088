
.ios-toggle, .ios-toggle:active{
  position:absolute;
  top:-5000px;
  height:0;
  width:0;
  opacity:0;
  border:none;
  outline:none;
}
.ios-toggle-label{
  display: inline-block;
  position:relative;
  padding:10px;
  font-size:12px;
  line-height:16px;
  width:51px;
  height:32px;
  margin-bottom: 0;
  /*border-radius*/
  -webkit-border-radius:16px;
  -moz-border-radius:16px;
  border-radius:16px;
  background: rgba(0,0,0, 0.5);
  cursor:pointer;
}
.ios-toggle-label:before{
  content:'';
  display:block;
  position:absolute;
  z-index:1;
  line-height:34px;
  text-indent:40px;
  height:28px;
  width:28px;
  /*border-radius*/
  -webkit-border-radius:100%;
  -moz-border-radius:100%;
  border-radius:100%;
  top:2px;
  left:2px;
  right:auto;
  background:white;

  transition: left .2s ease;
  /*box-shadow*/
  box-shadow:0 0 0 2px transparent,0 3px 3px rgba(0,0,0,.3);
}

.ios-toggle:checked + .ios-toggle-label{
  /*box-shadow*/
}
.ios-toggle:checked + .ios-toggle-label:before{
  left:calc(100% - 31px);
  /*box-shadow*/
  box-shadow:0 0 0 2px transparent,0 3px 3px rgba(0,0,0,.3);
}
.ios-toggle:checked + .ios-toggle-label:after{
  content:attr(data-on);
  left:60px;
  width:34px;
}
/* GREEN CHECKBOX */

#mrv-toggle + .ios-toggle-label{
  /*box-shadow*/
}
#mrv-toggle:checked + .ios-toggle-label{
  /*box-shadow*/
}
#mrv-toggle:checked + .ios-toggle-label{
}
#mrv-toggle:checked + .ios-toggle-label:after{
}


