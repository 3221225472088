#stage{
  height: calc(100vh - #{$head-height-sm});
  @media(min-width: $screen-sm-min){
    height: calc(100vh - #{$head-height + $subnav-height});
  }


  max-height: 600px;
  background: #eeeeee;

  position: relative;

  .map{
    height: 100%;
    width: 100%;
    overflow: hidden;


    position: relative;

    margin-left: auto;
    margin-right: auto;



    svg{

      position: absolute;

      margin: auto;
      height: 100%;
      top:-100%;
      right: -30%;
      bottom:-100%;

      min-width: 100%;

      max-height: 600px;
      max-width: 1200px;

      @media (min-width: $screen-sm-min) {
        right: 0;
      }
      @media (min-width: $screen-md-min) {
        right: 0;
      }

      @media(min-aspect-ratio: 3/2){
        height: auto;
        top:-100%;
        left:0;
        right: 0;
        bottom:-100%;
      }

      .port{
        fill: $brand-primary;
        stroke: $bg-blue;
        stroke-width: 0.3;
      }
    }

    #_02_Fläche_EUROPA_-_lock_cyan{
      opacity: 1;
      transition: 0.5s ease opacity;
    }
    #ports{
      opacity: 0;
      transition: 0.5s ease opacity;
    }


    #_02_Fläche_EUROPA_-_lock_cyan {

      cursor: pointer;

      #Icon_-_Lock > path:nth-child(2){
        transition: 0.5s ease transform;
      }
      &:hover{
        #Icon_-_Lock > path:nth-child(2){
          //transform: translateY(-3%);
        }
        #Europa-2{
          opacity: 1;
        }
      }
    }

  }
  &.unlocked{
    #_02_Fläche_EUROPA_-_lock_cyan{
      opacity: 0;
    }
    #ports{
      opacity: 1;
    }
  }
  .lip{
    position: absolute;
    bottom: 0;
    left: calc( 50% - 95px);
    width: 250px;
    border-radius: 4px 4px 0 0;
    padding: 9px 31px 8px 31px;
    background: #fff;


    font-family: 'avenirdemi';
    font-size: 16px;
    color: $brand-info;

    a:hover, a:focus{
      text-decoration: none;
    }
    span{
      padding-left: 15px;
    }
  }

  .controls{
    position: absolute;
    bottom: 0;
    overflow: hidden;

    @media(max-width: $screen-sm-max){
      width: 100%;
    }


    @media (min-width: $screen-md-min) {
      bottom: 52px;
      left: calc((100% - #{ $container-lg - $grid-gutter-width }) / 2);
    }

      > div{
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        @media(min-width: $screen-md-min){
          width: 300px;
          border-radius: 4px;
        }
        background: #fff;
      }


    .legend{
      @media(max-width: $screen-sm-max) {
        display: table;
        padding: 0;

        transform: translateX(calc( 100% - 20px ));
        transition: transform 0.5s ease;
        width: calc(100% - 10px );
        border-radius: 4px 0 0 4px;

        position: relative;

        &.in{
          transform: translateX(10px);
          &:before{
            content: $fa-var-chevron-right;
          }
        }

        &:before {
          display: table-cell;
          background: $brand-primary;
          width: 30px;
          height: 100%;
          font-family: FontAwesome;
          content: $fa-var-chevron-left;
          color: #fff;
          text-align: center;
          vertical-align: middle;
          border-radius: 4px 0 0 4px;
        }
        p, h4{
          padding-right: 20px;
        }
      }

      .legend-manager{
        margin: 13px 20px 0 20px;

        h2{
          color: $brand-primary;
          margin-bottom: 15px;
        }
      }
      .legend-inner{

        padding: 10px 20px 10px 40px;
        position: relative;

        &:before{
          content: '';
          background: $brand-primary;
          width: 9px;
          height: 9px;
          position: absolute;
          left: 15px;
          top: 25px;
          border-radius: 9px;
        }

        h4{

          line-height: 1.2;
        }

        @media (min-width: $screen-md-min) {
          padding-left: 60px;

          &:before{
            left: 25px;
          }
        }
      }

    }

    .switch{
      padding-bottom: 2px;
      background: $brand-danger;
      transition: .5s ease background;

      &.unlocked{
        background: $brand-success;
      }
      @media(max-width: $screen-sm-max){
        border-bottom: 10px solid $bg-blue;
      }

      h3{
        display: inline-block;
        margin: 8px 0 0 10px;
        color: #fff;
      }
    }

  }




  &:not(.unlocked){
    &.anonymous .legend,
    .legend-inner {
      display: none;
    }
    @media (max-width: $screen-sm-max) {
      .legend {
        display: none;
      }
    }
  }


  &.unlocked{
    .legend-manager{
      border-bottom: 1px solid $brand-primary;
    }
  }
}

@media(min-width: $grid-float-breakpoint){
  header.affix + #stage{
    margin-top: 50px;
  }
}


body.ios.safari #stage{
  height: calc(100vh - #{$head-height-sm + 66px});
}
