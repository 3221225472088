
.section{
  padding-top: 36px;
  padding-bottom: 36px;
  @media (min-width: $screen-sm-min) {
    padding-top: 76px;
    padding-bottom: 76px;
  }
}


#intro{
  
}

#request{
  background: $bg-grey;
  overflow: hidden;
}
#request-inset{
  background: $bg-grey;
  box-shadow:
    inset  0  16px 16px -16px #CCC,
    inset  0 -16px 16px -16px #CCC;

}
#request-form-wrapper{
  display: none;
}
#show-request-form, .show-request-button{
  font-size: 24px;
}

#about{
  padding-bottom: 0;
  .jumbotron p{
    padding-bottom: 40px;
  }
  .related{
    margin-top: 20px;
  }
  .row.related > div {
    > div{
      border-width: 0;
      border-color: $light-blue;
      border-style: solid;
      border-top-width: 1px;
      border-bottom-width: 1px;
      margin-top: -1px;
      padding-left: 5px;
      padding-right: 15px;

      min-height: 120px;

      font-size: 14px;
      a, a:hover{
        text-decoration: none;
        color: $font-base;
      }
      p{
        padding-left: 106px;
      }
    }
  }
  .compliance-mood{
    background: url('/images/labyrinth_visual.png') no-repeat;
    background-size: cover;
    background-position-x: 50%;
    height: 34vw;
    min-height: 200px;
  }

}