/* -------------------------- 
  BROADER VIEW MEDIUM 
---------------------------*/
@font-face {
  font-family: 'broaderviewmedium';
  src: url('/fonts/broaderview-medium-webfont.eot');
  src: url('/fonts/broaderview-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/broaderview-medium-webfont.woff2') format('woff2'),
       url('/fonts/broaderview-medium-webfont.woff') format('woff'),
       url('/fonts/broaderview-medium-webfont.ttf') format('truetype'),
       url('/fonts/broaderview-medium-webfont.svg#broaderviewmedium') format('svg');
  font-weight: normal;
  font-style: normal;
}



/* -------------------------- 
  AVENIR REGULAR
---------------------------*/
@font-face {
  font-family: 'avenirregular';
  src: url('/fonts/avenir-regular.eot');
  src: url('/fonts/avenir-regular.eot?#iefix') format('embedded-opentype'),
       url('/fonts/avenir-regular.woff') format('woff'),
       url('/fonts/avenir-regular.ttf') format('truetype'),
       url('/fonts/avenir-regular.svg#avenirregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* -------------------------- 
  AVENIR DEMI
---------------------------*/
@font-face {
  font-family: 'avenirdemi';
  src: url('/fonts/avenir-demi.eot');
  src: url('/fonts/avenir-demi.eot?#iefix') format('embedded-opentype'),
       url('/fonts/avenir-demi.woff') format('woff'),
       url('/fonts/avenir-demi.ttf') format('truetype'),
       url('/fonts/avenir-demi.svg#avenirdemi') format('svg');
  font-weight: normal;
  font-style: normal;
}


@import url(//fonts.googleapis.com/earlyaccess/cwtexfangsong.css);

/* -------------------------- 
  CUSTOM ICONS
  dnvgl-icons_mrv-ready
---------------------------*/
@font-face {
    font-family: 'dnvgl-icons_mrv-ready';
    src:  url('/fonts/dnvgl-icons_mrv-ready.eot?lfil2j');
    src:  url('/fonts/dnvgl-icons_mrv-ready.eot?lfil2j#iefix') format('embedded-opentype'),
          url('/fonts/dnvgl-icons_mrv-ready.ttf?lfil2j') format('truetype'),
          url('/fonts/dnvgl-icons_mrv-ready.woff?lfil2j') format('woff'),
          url('/fonts/dnvgl-icons_mrv-ready.svg?lfil2j#dnvgl-icons_mrv-ready') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon-dnvgl {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'dnvgl-icons_mrv-ready' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-dnvgl-icon_barrel-hpo:before {
    content: "\71";
}
.icon-dnvgl-icon_barrel-mgo:before {
    content: "\77";
}
.icon-dnvgl-icon_calendar:before {
    content: "\65";
}
.icon-dnvgl-icon_certificate:before {
    content: "\72";
}
.icon-dnvgl-icon_check:before {
    content: "\74";
}
.icon-dnvgl-icon_co2-doc-checked:before {
    content: "\7a";
}
.icon-dnvgl-icon_co2-doc:before {
    content: "\75";
}
.icon-dnvgl-icon_device-laptop:before {
    content: "\69";
}
.icon-dnvgl-icon_device-smartphone:before {
    content: "\6f";
}
.icon-dnvgl-icon_device-tablet:before {
    content: "\70";
}
.icon-dnvgl-icon_excel-doc-checked:before {
    content: "\61";
}
.icon-dnvgl-icon_excel-doc:before {
    content: "\73";
}
.icon-dnvgl-icon_excel-pile:before {
    content: "\64";
}
.icon-dnvgl-icon_monitoring-doc:before {
    content: "\66";
}
.icon-dnvgl-icon_new-check:before {
    content: "\67";
}
.icon-dnvgl-icon_pdf-download:before {
    content: "\68";
}
.icon-dnvgl-icon_print:before {
    content: "\6a";
}
.icon-dnvgl-icon_send-email:before {
    content: "\6b";
}
.icon-dnvgl-icon_verification-small:before {
    content: "\6c";
}



/* -------------------------- 
  MATERIAL DESIGN ICONS
  MaterialDesignIcons.com 
---------------------------*/
@font-face {
  font-family: 'MaterialDesignIcons';
  src: url("fonts/materialdesignicons-webfont.eot?v=1.1.34");
  src:  url("fonts/materialdesignicons-webfont.eot?#iefix&v=1.1.34") format("embedded-opentype"), 
        url("fonts/materialdesignicons-webfont.woff2?v=1.1.34") format("woff2"), 
        url("fonts/materialdesignicons-webfont.woff?v=1.1.34") format("woff"), 
        url("fonts/materialdesignicons-webfont.ttf?v=1.1.34") format("truetype"), 
        url("fonts/materialdesignicons-webfont.svg?v=1.1.34#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mdi {
  display: inline-block;
  font: normal normal normal 24px/1 MaterialDesignIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.mdi-account::before {
  content: "\f101";
}

.mdi-account-alert::before {
  content: "\f102";
}

.mdi-account-box::before {
  content: "\f103";
}

.mdi-account-box-outline::before {
  content: "\f104";
}

.mdi-account-check::before {
  content: "\f105";
}

.mdi-account-circle::before {
  content: "\f106";
}

.mdi-account-key::before {
  content: "\f107";
}

.mdi-account-location::before {
  content: "\f108";
}

.mdi-account-minus::before {
  content: "\f109";
}

.mdi-account-multiple::before {
  content: "\f10a";
}

.mdi-account-multiple-outline::before {
  content: "\f10b";
}

.mdi-account-multiple-plus::before {
  content: "\f10c";
}

.mdi-account-network::before {
  content: "\f10d";
}

.mdi-account-outline::before {
  content: "\f10e";
}

.mdi-account-plus::before {
  content: "\f10f";
}

.mdi-account-remove::before {
  content: "\f110";
}

.mdi-account-search::before {
  content: "\f111";
}

.mdi-account-star::before {
  content: "\f112";
}

.mdi-account-star-variant::before {
  content: "\f113";
}

.mdi-account-switch::before {
  content: "\f114";
}

.mdi-airballoon::before {
  content: "\f115";
}

.mdi-airplane::before {
  content: "\f116";
}

.mdi-airplane-off::before {
  content: "\f117";
}

.mdi-alarm::before {
  content: "\f118";
}

.mdi-alarm-check::before {
  content: "\f119";
}

.mdi-alarm-multiple::before {
  content: "\f11a";
}

.mdi-alarm-off::before {
  content: "\f11b";
}

.mdi-alarm-plus::before {
  content: "\f11c";
}

.mdi-album::before {
  content: "\f11d";
}

.mdi-alert::before {
  content: "\f11e";
}

.mdi-alert-box::before {
  content: "\f11f";
}

.mdi-alert-circle::before {
  content: "\f120";
}

.mdi-alert-octagon::before {
  content: "\f121";
}

.mdi-alpha::before {
  content: "\f122";
}

.mdi-alphabetical::before {
  content: "\f123";
}

.mdi-amazon::before {
  content: "\f124";
}

.mdi-amazon-clouddrive::before {
  content: "\f125";
}

.mdi-ambulance::before {
  content: "\f126";
}

.mdi-android::before {
  content: "\f127";
}

.mdi-android-debug-bridge::before {
  content: "\f128";
}

.mdi-android-studio::before {
  content: "\f129";
}

.mdi-apple::before {
  content: "\f12a";
}

.mdi-apple-finder::before {
  content: "\f12b";
}

.mdi-apple-mobileme::before {
  content: "\f12c";
}

.mdi-apple-safari::before {
  content: "\f12d";
}

.mdi-appnet::before {
  content: "\f12e";
}

.mdi-apps::before {
  content: "\f12f";
}

.mdi-archive::before {
  content: "\f130";
}

.mdi-arrange-bring-forward::before {
  content: "\f131";
}

.mdi-arrange-bring-to-front::before {
  content: "\f132";
}

.mdi-arrange-send-backward::before {
  content: "\f133";
}

.mdi-arrange-send-to-back::before {
  content: "\f134";
}

.mdi-arrow-all::before {
  content: "\f135";
}

.mdi-arrow-collapse::before {
  content: "\f136";
}

.mdi-arrow-down::before {
  content: "\f137";
}

.mdi-arrow-down-bold::before {
  content: "\f138";
}

.mdi-arrow-down-bold-circle::before {
  content: "\f139";
}

.mdi-arrow-down-bold-circle-outline::before {
  content: "\f13a";
}

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\f13b";
}

.mdi-arrow-expand::before {
  content: "\f13c";
}

.mdi-arrow-left::before {
  content: "\f13d";
}

.mdi-arrow-left-bold::before {
  content: "\f13e";
}

.mdi-arrow-left-bold-circle::before {
  content: "\f13f";
}

.mdi-arrow-left-bold-circle-outline::before {
  content: "\f140";
}

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\f141";
}

.mdi-arrow-right::before {
  content: "\f142";
}

.mdi-arrow-right-bold::before {
  content: "\f143";
}

.mdi-arrow-right-bold-circle::before {
  content: "\f144";
}

.mdi-arrow-right-bold-circle-outline::before {
  content: "\f145";
}

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\f146";
}

.mdi-arrow-up::before {
  content: "\f147";
}

.mdi-arrow-up-bold::before {
  content: "\f148";
}

.mdi-arrow-up-bold-circle::before {
  content: "\f149";
}

.mdi-arrow-up-bold-circle-outline::before {
  content: "\f14a";
}

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\f14b";
}

.mdi-at::before {
  content: "\f14c";
}

.mdi-attachment::before {
  content: "\f14d";
}

.mdi-auto-fix::before {
  content: "\f14e";
}

.mdi-auto-upload::before {
  content: "\f14f";
}

.mdi-backup-restore::before {
  content: "\f150";
}

.mdi-bank::before {
  content: "\f151";
}

.mdi-barcode::before {
  content: "\f152";
}

.mdi-barley::before {
  content: "\f153";
}

.mdi-barrel::before {
  content: "\f154";
}

.mdi-basecamp::before {
  content: "\f155";
}

.mdi-basket::before {
  content: "\f156";
}

.mdi-basket-fill::before {
  content: "\f157";
}

.mdi-basket-unfill::before {
  content: "\f158";
}

.mdi-battery::before {
  content: "\f159";
}

.mdi-battery-20::before {
  content: "\f15a";
}

.mdi-battery-30::before {
  content: "\f15b";
}

.mdi-battery-40::before {
  content: "\f15c";
}

.mdi-battery-60::before {
  content: "\f15d";
}

.mdi-battery-80::before {
  content: "\f15e";
}

.mdi-battery-90::before {
  content: "\f15f";
}

.mdi-battery-alert::before {
  content: "\f160";
}

.mdi-battery-charging-100::before {
  content: "\f161";
}

.mdi-battery-charging-20::before {
  content: "\f162";
}

.mdi-battery-charging-30::before {
  content: "\f163";
}

.mdi-battery-charging-40::before {
  content: "\f164";
}

.mdi-battery-charging-60::before {
  content: "\f165";
}

.mdi-battery-charging-80::before {
  content: "\f166";
}

.mdi-battery-charging-90::before {
  content: "\f167";
}

.mdi-battery-minus::before {
  content: "\f168";
}

.mdi-battery-negative::before {
  content: "\f169";
}

.mdi-battery-outline::before {
  content: "\f16a";
}

.mdi-battery-plus::before {
  content: "\f16b";
}

.mdi-battery-positive::before {
  content: "\f16c";
}

.mdi-battery-unknown::before {
  content: "\f16d";
}

.mdi-beach::before {
  content: "\f16e";
}

.mdi-beaker::before {
  content: "\f16f";
}

.mdi-beaker-empty::before {
  content: "\f170";
}

.mdi-beaker-empty-outline::before {
  content: "\f171";
}

.mdi-beaker-outline::before {
  content: "\f172";
}

.mdi-beats::before {
  content: "\f173";
}

.mdi-beer::before {
  content: "\f174";
}

.mdi-behance::before {
  content: "\f175";
}

.mdi-bell::before {
  content: "\f176";
}

.mdi-bell-off::before {
  content: "\f177";
}

.mdi-bell-outline::before {
  content: "\f178";
}

.mdi-bell-ring::before {
  content: "\f179";
}

.mdi-bell-ring-outline::before {
  content: "\f17a";
}

.mdi-bell-sleep::before {
  content: "\f17b";
}

.mdi-beta::before {
  content: "\f17c";
}

.mdi-bike::before {
  content: "\f17d";
}

.mdi-bing::before {
  content: "\f17e";
}

.mdi-bio::before {
  content: "\f17f";
}

.mdi-biohazard::before {
  content: "\f180";
}

.mdi-black-mesa::before {
  content: "\f181";
}

.mdi-blackberry::before {
  content: "\f182";
}

.mdi-blinds::before {
  content: "\f183";
}

.mdi-block-helper::before {
  content: "\f184";
}

.mdi-blogger::before {
  content: "\f185";
}

.mdi-bluetooth::before {
  content: "\f186";
}

.mdi-bluetooth-audio::before {
  content: "\f187";
}

.mdi-bluetooth-connect::before {
  content: "\f188";
}

.mdi-bluetooth-settings::before {
  content: "\f189";
}

.mdi-blur::before {
  content: "\f18a";
}

.mdi-blur-linear::before {
  content: "\f18b";
}

.mdi-blur-off::before {
  content: "\f18c";
}

.mdi-blur-radial::before {
  content: "\f18d";
}

.mdi-bone::before {
  content: "\f18e";
}

.mdi-book::before {
  content: "\f18f";
}

.mdi-book-multiple::before {
  content: "\f190";
}

.mdi-book-multiple-variant::before {
  content: "\f191";
}

.mdi-book-open::before {
  content: "\f192";
}

.mdi-book-variant::before {
  content: "\f193";
}

.mdi-bookmark::before {
  content: "\f194";
}

.mdi-bookmark-check::before {
  content: "\f195";
}

.mdi-bookmark-outline::before {
  content: "\f196";
}

.mdi-bookmark-outline-plus::before {
  content: "\f197";
}

.mdi-bookmark-plus::before {
  content: "\f198";
}

.mdi-bookmark-remove::before {
  content: "\f199";
}

.mdi-border-all::before {
  content: "\f19a";
}

.mdi-border-bottom::before {
  content: "\f19b";
}

.mdi-border-color::before {
  content: "\f19c";
}

.mdi-border-horizontal::before {
  content: "\f19d";
}

.mdi-border-inside::before {
  content: "\f19e";
}

.mdi-border-left::before {
  content: "\f19f";
}

.mdi-border-none::before {
  content: "\f1a0";
}

.mdi-border-outside::before {
  content: "\f1a1";
}

.mdi-border-right::before {
  content: "\f1a2";
}

.mdi-border-top::before {
  content: "\f1a3";
}

.mdi-border-vertical::before {
  content: "\f1a4";
}

.mdi-bowling::before {
  content: "\f1a5";
}

.mdi-box::before {
  content: "\f1a6";
}

.mdi-briefcase::before {
  content: "\f1a7";
}

.mdi-briefcase-check::before {
  content: "\f1a8";
}

.mdi-briefcase-download::before {
  content: "\f1a9";
}

.mdi-briefcase-upload::before {
  content: "\f1aa";
}

.mdi-brightness-1::before {
  content: "\f1ab";
}

.mdi-brightness-2::before {
  content: "\f1ac";
}

.mdi-brightness-3::before {
  content: "\f1ad";
}

.mdi-brightness-4::before {
  content: "\f1ae";
}

.mdi-brightness-5::before {
  content: "\f1af";
}

.mdi-brightness-6::before {
  content: "\f1b0";
}

.mdi-brightness-7::before {
  content: "\f1b1";
}

.mdi-brightness-auto::before {
  content: "\f1b2";
}

.mdi-broom::before {
  content: "\f1b3";
}

.mdi-brush::before {
  content: "\f1b4";
}

.mdi-bug::before {
  content: "\f1b5";
}

.mdi-bullhorn::before {
  content: "\f1b6";
}

.mdi-bus::before {
  content: "\f1b7";
}

.mdi-cake::before {
  content: "\f1b8";
}

.mdi-cake-variant::before {
  content: "\f1b9";
}

.mdi-calculator::before {
  content: "\f1ba";
}

.mdi-calendar::before {
  content: "\f1bb";
}

.mdi-calendar-blank::before {
  content: "\f1bc";
}

.mdi-calendar-check::before {
  content: "\f1bd";
}

.mdi-calendar-clock::before {
  content: "\f1be";
}

.mdi-calendar-multiple::before {
  content: "\f1bf";
}

.mdi-calendar-multiple-check::before {
  content: "\f1c0";
}

.mdi-calendar-plus::before {
  content: "\f1c1";
}

.mdi-calendar-remove::before {
  content: "\f1c2";
}

.mdi-calendar-text::before {
  content: "\f1c3";
}

.mdi-calendar-today::before {
  content: "\f1c4";
}

.mdi-camcorder::before {
  content: "\f1c5";
}

.mdi-camcorder-box::before {
  content: "\f1c6";
}

.mdi-camcorder-box-off::before {
  content: "\f1c7";
}

.mdi-camcorder-off::before {
  content: "\f1c8";
}

.mdi-camera::before {
  content: "\f1c9";
}

.mdi-camera-iris::before {
  content: "\f1ca";
}

.mdi-camera-party-mode::before {
  content: "\f1cb";
}

.mdi-camera-switch::before {
  content: "\f1cc";
}

.mdi-camera-timer::before {
  content: "\f1cd";
}

.mdi-candycane::before {
  content: "\f1ce";
}

.mdi-car::before {
  content: "\f1cf";
}

.mdi-car-wash::before {
  content: "\f1d0";
}

.mdi-carrot::before {
  content: "\f1d1";
}

.mdi-cart::before {
  content: "\f1d2";
}

.mdi-cart-outline::before {
  content: "\f1d3";
}

.mdi-cash::before {
  content: "\f1d4";
}

.mdi-cash-multiple::before {
  content: "\f1d5";
}

.mdi-cash-usd::before {
  content: "\f1d6";
}

.mdi-cast::before {
  content: "\f1d7";
}

.mdi-cast-connected::before {
  content: "\f1d8";
}

.mdi-castle::before {
  content: "\f1d9";
}

.mdi-cellphone::before {
  content: "\f1da";
}

.mdi-cellphone-android::before {
  content: "\f1db";
}

.mdi-cellphone-dock::before {
  content: "\f1dc";
}

.mdi-cellphone-iphone::before {
  content: "\f1dd";
}

.mdi-cellphone-link::before {
  content: "\f1de";
}

.mdi-cellphone-link-off::before {
  content: "\f1df";
}

.mdi-cellphone-settings::before {
  content: "\f1e0";
}

.mdi-chair-school::before {
  content: "\f1e1";
}

.mdi-chart-arc::before {
  content: "\f1e2";
}

.mdi-chart-bar::before {
  content: "\f1e3";
}

.mdi-chart-histogram::before {
  content: "\f1e4";
}

.mdi-chart-line::before {
  content: "\f1e5";
}

.mdi-chart-pie::before {
  content: "\f1e6";
}

.mdi-check::before {
  content: "\f1e7";
}

.mdi-check-all::before {
  content: "\f1e8";
}

.mdi-checkbox-blank::before {
  content: "\f1e9";
}

.mdi-checkbox-blank-circle::before {
  content: "\f1ea";
}

.mdi-checkbox-blank-circle-outline::before {
  content: "\f1eb";
}

.mdi-checkbox-blank-outline::before {
  content: "\f1ec";
}

.mdi-checkbox-marked::before {
  content: "\f1ed";
}

.mdi-checkbox-marked-circle::before {
  content: "\f1ee";
}

.mdi-checkbox-marked-circle-outline::before {
  content: "\f1ef";
}

.mdi-checkbox-marked-outline::before {
  content: "\f1f0";
}

.mdi-checkbox-multiple-blank::before {
  content: "\f1f1";
}

.mdi-checkbox-multiple-blank-outline::before {
  content: "\f1f2";
}

.mdi-checkbox-multiple-marked::before {
  content: "\f1f3";
}

.mdi-checkbox-multiple-marked-outline::before {
  content: "\f1f4";
}

.mdi-checkerboard::before {
  content: "\f1f5";
}

.mdi-chevron-double-down::before {
  content: "\f1f6";
}

.mdi-chevron-double-left::before {
  content: "\f1f7";
}

.mdi-chevron-double-right::before {
  content: "\f1f8";
}

.mdi-chevron-double-up::before {
  content: "\f1f9";
}

.mdi-chevron-down::before {
  content: "\f1fa";
}

.mdi-chevron-left::before {
  content: "\f1fb";
}

.mdi-chevron-right::before {
  content: "\f1fc";
}

.mdi-chevron-up::before {
  content: "\f1fd";
}

.mdi-church::before {
  content: "\f1fe";
}

.mdi-cisco-webex::before {
  content: "\f1ff";
}

.mdi-city::before {
  content: "\f200";
}

.mdi-clipboard::before {
  content: "\f201";
}

.mdi-clipboard-account::before {
  content: "\f202";
}

.mdi-clipboard-alert::before {
  content: "\f203";
}

.mdi-clipboard-arrow-down::before {
  content: "\f204";
}

.mdi-clipboard-arrow-left::before {
  content: "\f205";
}

.mdi-clipboard-check::before {
  content: "\f206";
}

.mdi-clipboard-outline::before {
  content: "\f207";
}

.mdi-clipboard-text::before {
  content: "\f208";
}

.mdi-clippy::before {
  content: "\f209";
}

.mdi-clock::before {
  content: "\f20a";
}

.mdi-clock-fast::before {
  content: "\f20b";
}

.mdi-close::before {
  content: "\f20c";
}

.mdi-close-box::before {
  content: "\f20d";
}

.mdi-close-box-outline::before {
  content: "\f20e";
}

.mdi-close-circle::before {
  content: "\f20f";
}

.mdi-close-circle-outline::before {
  content: "\f210";
}

.mdi-close-network::before {
  content: "\f211";
}

.mdi-closed-caption::before {
  content: "\f212";
}

.mdi-cloud::before {
  content: "\f213";
}

.mdi-cloud-check::before {
  content: "\f214";
}

.mdi-cloud-circle::before {
  content: "\f215";
}

.mdi-cloud-download::before {
  content: "\f216";
}

.mdi-cloud-outline::before {
  content: "\f217";
}

.mdi-cloud-outline-off::before {
  content: "\f218";
}

.mdi-cloud-upload::before {
  content: "\f219";
}

.mdi-code-array::before {
  content: "\f21a";
}

.mdi-code-string::before {
  content: "\f21b";
}

.mdi-coffee::before {
  content: "\f21c";
}

.mdi-coffee-to-go::before {
  content: "\f21d";
}

.mdi-coin::before {
  content: "\f21e";
}

.mdi-color-helper::before {
  content: "\f21f";
}

.mdi-comment::before {
  content: "\f220";
}

.mdi-comment-account::before {
  content: "\f221";
}

.mdi-comment-account-outline::before {
  content: "\f222";
}

.mdi-comment-alert::before {
  content: "\f223";
}

.mdi-comment-alert-outline::before {
  content: "\f224";
}

.mdi-comment-check::before {
  content: "\f225";
}

.mdi-comment-check-outline::before {
  content: "\f226";
}

.mdi-comment-multiple-outline::before {
  content: "\f227";
}

.mdi-comment-outline::before {
  content: "\f228";
}

.mdi-comment-plus-outline::before {
  content: "\f229";
}

.mdi-comment-processing::before {
  content: "\f22a";
}

.mdi-comment-processing-outline::before {
  content: "\f22b";
}

.mdi-comment-remove-outline::before {
  content: "\f22c";
}

.mdi-comment-text::before {
  content: "\f22d";
}

.mdi-comment-text-outline::before {
  content: "\f22e";
}

.mdi-compare::before {
  content: "\f22f";
}

.mdi-compass::before {
  content: "\f230";
}

.mdi-compass-outline::before {
  content: "\f231";
}

.mdi-console::before {
  content: "\f232";
}

.mdi-content-copy::before {
  content: "\f233";
}

.mdi-content-cut::before {
  content: "\f234";
}

.mdi-content-paste::before {
  content: "\f235";
}

.mdi-content-save::before {
  content: "\f236";
}

.mdi-content-save-all::before {
  content: "\f237";
}

.mdi-contrast::before {
  content: "\f238";
}

.mdi-contrast-box::before {
  content: "\f239";
}

.mdi-contrast-circle::before {
  content: "\f23a";
}

.mdi-cow::before {
  content: "\f23b";
}

.mdi-credit-card::before {
  content: "\f23c";
}

.mdi-credit-card-multiple::before {
  content: "\f23d";
}

.mdi-crop::before {
  content: "\f23e";
}

.mdi-crop-free::before {
  content: "\f23f";
}

.mdi-crop-landscape::before {
  content: "\f240";
}

.mdi-crop-portrait::before {
  content: "\f241";
}

.mdi-crop-square::before {
  content: "\f242";
}

.mdi-crosshairs::before {
  content: "\f243";
}

.mdi-crosshairs-gps::before {
  content: "\f244";
}

.mdi-cube::before {
  content: "\f245";
}

.mdi-cube-outline::before {
  content: "\f246";
}

.mdi-cube-unfolded::before {
  content: "\f247";
}

.mdi-cup::before {
  content: "\f248";
}

.mdi-cup-water::before {
  content: "\f249";
}

.mdi-currency-btc::before {
  content: "\f24a";
}

.mdi-currency-eur::before {
  content: "\f24b";
}

.mdi-currency-gbp::before {
  content: "\f24c";
}

.mdi-currency-try::before {
  content: "\f24d";
}

.mdi-currency-usd::before {
  content: "\f24e";
}

.mdi-cursor-default::before {
  content: "\f24f";
}

.mdi-cursor-default-outline::before {
  content: "\f250";
}

.mdi-cursor-move::before {
  content: "\f251";
}

.mdi-cursor-pointer::before {
  content: "\f252";
}

.mdi-database::before {
  content: "\f253";
}

.mdi-database-minus::before {
  content: "\f254";
}

.mdi-database-outline::before {
  content: "\f255";
}

.mdi-database-plus::before {
  content: "\f256";
}

.mdi-debug-step-into::before {
  content: "\f257";
}

.mdi-debug-step-out::before {
  content: "\f258";
}

.mdi-debug-step-over::before {
  content: "\f259";
}

.mdi-delete::before {
  content: "\f25a";
}

.mdi-delete-variant::before {
  content: "\f25b";
}

.mdi-deskphone::before {
  content: "\f25c";
}

.mdi-desktop-mac::before {
  content: "\f25d";
}

.mdi-desktop-tower::before {
  content: "\f25e";
}

.mdi-details::before {
  content: "\f25f";
}

.mdi-deviantart::before {
  content: "\f260";
}

.mdi-dice::before {
  content: "\f261";
}

.mdi-dice-1::before {
  content: "\f262";
}

.mdi-dice-2::before {
  content: "\f263";
}

.mdi-dice-3::before {
  content: "\f264";
}

.mdi-dice-4::before {
  content: "\f265";
}

.mdi-dice-5::before {
  content: "\f266";
}

.mdi-dice-6::before {
  content: "\f267";
}

.mdi-directions::before {
  content: "\f268";
}

.mdi-disk-alert::before {
  content: "\f269";
}

.mdi-disqus::before {
  content: "\f26a";
}

.mdi-disqus-outline::before {
  content: "\f26b";
}

.mdi-division::before {
  content: "\f26c";
}

.mdi-division-box::before {
  content: "\f26d";
}

.mdi-dns::before {
  content: "\f26e";
}

.mdi-domain::before {
  content: "\f26f";
}

.mdi-dots-horizontal::before {
  content: "\f270";
}

.mdi-dots-vertical::before {
  content: "\f271";
}

.mdi-download::before {
  content: "\f272";
}

.mdi-drag::before {
  content: "\f273";
}

.mdi-drag-horizontal::before {
  content: "\f274";
}

.mdi-drag-vertical::before {
  content: "\f275";
}

.mdi-drawing::before {
  content: "\f276";
}

.mdi-drawing-box::before {
  content: "\f277";
}

.mdi-dribbble::before {
  content: "\f278";
}

.mdi-dribbble-box::before {
  content: "\f279";
}

.mdi-drone::before {
  content: "\f27a";
}

.mdi-dropbox::before {
  content: "\f27b";
}

.mdi-duck::before {
  content: "\f27c";
}

.mdi-dumbbell::before {
  content: "\f27d";
}

.mdi-earth::before {
  content: "\f27e";
}

.mdi-earth-off::before {
  content: "\f27f";
}

.mdi-edge::before {
  content: "\f280";
}

.mdi-elevation-decline::before {
  content: "\f281";
}

.mdi-elevation-rise::before {
  content: "\f282";
}

.mdi-email::before {
  content: "\f283";
}

.mdi-email-open::before {
  content: "\f284";
}

.mdi-email-outline::before {
  content: "\f285";
}

.mdi-emoticon::before {
  content: "\f286";
}

.mdi-emoticon-cool::before {
  content: "\f287";
}

.mdi-emoticon-devil::before {
  content: "\f288";
}

.mdi-emoticon-happy::before {
  content: "\f289";
}

.mdi-emoticon-neutral::before {
  content: "\f28a";
}

.mdi-emoticon-poop::before {
  content: "\f28b";
}

.mdi-emoticon-sad::before {
  content: "\f28c";
}

.mdi-emoticon-tongue::before {
  content: "\f28d";
}

.mdi-equal::before {
  content: "\f28e";
}

.mdi-equal-box::before {
  content: "\f28f";
}

.mdi-eraser::before {
  content: "\f290";
}

.mdi-escalator::before {
  content: "\f291";
}

.mdi-etsy::before {
  content: "\f292";
}

.mdi-evernote::before {
  content: "\f293";
}

.mdi-exclamation::before {
  content: "\f294";
}

.mdi-exit-to-app::before {
  content: "\f295";
}

.mdi-eye::before {
  content: "\f296";
}

.mdi-eye-off::before {
  content: "\f297";
}

.mdi-eyedropper::before {
  content: "\f298";
}

.mdi-eyedropper-variant::before {
  content: "\f299";
}

.mdi-facebook::before {
  content: "\f29a";
}

.mdi-facebook-box::before {
  content: "\f29b";
}

.mdi-facebook-messenger::before {
  content: "\f29c";
}

.mdi-factory::before {
  content: "\f29d";
}

.mdi-fan::before {
  content: "\f29e";
}

.mdi-fast-forward::before {
  content: "\f29f";
}

.mdi-ferry::before {
  content: "\f2a0";
}

.mdi-file::before {
  content: "\f2a1";
}

.mdi-file-cloud::before {
  content: "\f2a2";
}

.mdi-file-delimited::before {
  content: "\f2a3";
}

.mdi-file-document::before {
  content: "\f2a4";
}

.mdi-file-document-box::before {
  content: "\f2a5";
}

.mdi-file-excel::before {
  content: "\f2a6";
}

.mdi-file-excel-box::before {
  content: "\f2a7";
}

.mdi-file-find::before {
  content: "\f2a8";
}

.mdi-file-image::before {
  content: "\f2a9";
}

.mdi-file-image-box::before {
  content: "\f2aa";
}

.mdi-file-music::before {
  content: "\f2ab";
}

.mdi-file-outline::before {
  content: "\f2ac";
}

.mdi-file-pdf::before {
  content: "\f2ad";
}

.mdi-file-pdf-box::before {
  content: "\f2ae";
}

.mdi-file-powerpoint::before {
  content: "\f2af";
}

.mdi-file-powerpoint-box::before {
  content: "\f2b0";
}

.mdi-file-presentation-box::before {
  content: "\f2b1";
}

.mdi-file-video::before {
  content: "\f2b2";
}

.mdi-file-word::before {
  content: "\f2b3";
}

.mdi-file-word-box::before {
  content: "\f2b4";
}

.mdi-file-xml::before {
  content: "\f2b5";
}

.mdi-film::before {
  content: "\f2b6";
}

.mdi-filmstrip::before {
  content: "\f2b7";
}

.mdi-filmstrip-off::before {
  content: "\f2b8";
}

.mdi-filter::before {
  content: "\f2b9";
}

.mdi-filter-outline::before {
  content: "\f2ba";
}

.mdi-filter-remove::before {
  content: "\f2bb";
}

.mdi-filter-remove-outline::before {
  content: "\f2bc";
}

.mdi-filter-variant::before {
  content: "\f2bd";
}

.mdi-fire::before {
  content: "\f2be";
}

.mdi-firefox::before {
  content: "\f2bf";
}

.mdi-fish::before {
  content: "\f2c0";
}

.mdi-flag::before {
  content: "\f2c1";
}

.mdi-flag-checkered::before {
  content: "\f2c2";
}

.mdi-flag-outline::before {
  content: "\f2c3";
}

.mdi-flag-outline-variant::before {
  content: "\f2c4";
}

.mdi-flag-triangle::before {
  content: "\f2c5";
}

.mdi-flag-variant::before {
  content: "\f2c6";
}

.mdi-flash::before {
  content: "\f2c7";
}

.mdi-flash-auto::before {
  content: "\f2c8";
}

.mdi-flash-off::before {
  content: "\f2c9";
}

.mdi-flashlight::before {
  content: "\f2ca";
}

.mdi-flashlight-off::before {
  content: "\f2cb";
}

.mdi-flip-to-back::before {
  content: "\f2cc";
}

.mdi-flip-to-front::before {
  content: "\f2cd";
}

.mdi-floppy::before {
  content: "\f2ce";
}

.mdi-flower::before {
  content: "\f2cf";
}

.mdi-folder::before {
  content: "\f2d0";
}

.mdi-folder-account::before {
  content: "\f2d1";
}

.mdi-folder-download::before {
  content: "\f2d2";
}

.mdi-folder-google-drive::before {
  content: "\f2d3";
}

.mdi-folder-image::before {
  content: "\f2d4";
}

.mdi-folder-lock::before {
  content: "\f2d5";
}

.mdi-folder-lock-open::before {
  content: "\f2d6";
}

.mdi-folder-move::before {
  content: "\f2d7";
}

.mdi-folder-multiple::before {
  content: "\f2d8";
}

.mdi-folder-multiple-image::before {
  content: "\f2d9";
}

.mdi-folder-multiple-outline::before {
  content: "\f2da";
}

.mdi-folder-outline::before {
  content: "\f2db";
}

.mdi-folder-plus::before {
  content: "\f2dc";
}

.mdi-folder-remove::before {
  content: "\f2dd";
}

.mdi-folder-upload::before {
  content: "\f2de";
}

.mdi-food::before {
  content: "\f2df";
}

.mdi-food-apple::before {
  content: "\f2e0";
}

.mdi-food-variant::before {
  content: "\f2e1";
}

.mdi-football::before {
  content: "\f2e2";
}

.mdi-football-helmet::before {
  content: "\f2e3";
}

.mdi-format-align-center::before {
  content: "\f2e4";
}

.mdi-format-align-justify::before {
  content: "\f2e5";
}

.mdi-format-align-left::before {
  content: "\f2e6";
}

.mdi-format-align-right::before {
  content: "\f2e7";
}

.mdi-format-bold::before {
  content: "\f2e8";
}

.mdi-format-clear::before {
  content: "\f2e9";
}

.mdi-format-color-fill::before {
  content: "\f2ea";
}

.mdi-format-header-1::before {
  content: "\f2eb";
}

.mdi-format-header-2::before {
  content: "\f2ec";
}

.mdi-format-header-3::before {
  content: "\f2ed";
}

.mdi-format-header-4::before {
  content: "\f2ee";
}

.mdi-format-header-5::before {
  content: "\f2ef";
}

.mdi-format-header-6::before {
  content: "\f2f0";
}

.mdi-format-header-pound::before {
  content: "\f2f1";
}

.mdi-format-indent-decrease::before {
  content: "\f2f2";
}

.mdi-format-indent-increase::before {
  content: "\f2f3";
}

.mdi-format-italic::before {
  content: "\f2f4";
}

.mdi-format-line-spacing::before {
  content: "\f2f5";
}

.mdi-format-list-bulleted::before {
  content: "\f2f6";
}

.mdi-format-list-numbers::before {
  content: "\f2f7";
}

.mdi-format-paint::before {
  content: "\f2f8";
}

.mdi-format-paragraph::before {
  content: "\f2f9";
}

.mdi-format-quote::before {
  content: "\f2fa";
}

.mdi-format-size::before {
  content: "\f2fb";
}

.mdi-format-strikethrough::before {
  content: "\f2fc";
}

.mdi-format-subscript::before {
  content: "\f2fd";
}

.mdi-format-superscript::before {
  content: "\f2fe";
}

.mdi-format-textdirection-l-to-r::before {
  content: "\f2ff";
}

.mdi-format-textdirection-r-to-l::before {
  content: "\f300";
}

.mdi-format-underline::before {
  content: "\f301";
}

.mdi-forum::before {
  content: "\f302";
}

.mdi-forward::before {
  content: "\f303";
}

.mdi-foursquare::before {
  content: "\f304";
}

.mdi-fridge::before {
  content: "\f305";
}

.mdi-fullscreen::before {
  content: "\f306";
}

.mdi-fullscreen-exit::before {
  content: "\f307";
}

.mdi-function::before {
  content: "\f308";
}

.mdi-gamepad::before {
  content: "\f309";
}

.mdi-gamepad-variant::before {
  content: "\f30a";
}

.mdi-gas-station::before {
  content: "\f30b";
}

.mdi-gavel::before {
  content: "\f30c";
}

.mdi-gender-female::before {
  content: "\f30d";
}

.mdi-gender-male::before {
  content: "\f30e";
}

.mdi-gender-transgender::before {
  content: "\f30f";
}

.mdi-gift::before {
  content: "\f310";
}

.mdi-github-box::before {
  content: "\f311";
}

.mdi-github-circle::before {
  content: "\f312";
}

.mdi-glass-flute::before {
  content: "\f313";
}

.mdi-glass-mug::before {
  content: "\f314";
}

.mdi-glass-stange::before {
  content: "\f315";
}

.mdi-glass-tulip::before {
  content: "\f316";
}

.mdi-gmail::before {
  content: "\f317";
}

.mdi-google::before {
  content: "\f318";
}

.mdi-google-chrome::before {
  content: "\f319";
}

.mdi-google-circles::before {
  content: "\f31a";
}

.mdi-google-circles-communities::before {
  content: "\f31b";
}

.mdi-google-circles-extended::before {
  content: "\f31c";
}

.mdi-google-circles-group::before {
  content: "\f31d";
}

.mdi-google-controller::before {
  content: "\f31e";
}

.mdi-google-controller-off::before {
  content: "\f31f";
}

.mdi-google-drive::before {
  content: "\f320";
}

.mdi-google-earth::before {
  content: "\f321";
}

.mdi-google-glass::before {
  content: "\f322";
}

.mdi-google-maps::before {
  content: "\f323";
}

.mdi-google-pages::before {
  content: "\f324";
}

.mdi-google-play::before {
  content: "\f325";
}

.mdi-google-plus::before {
  content: "\f326";
}

.mdi-google-plus-box::before {
  content: "\f327";
}

.mdi-guitar-pick::before {
  content: "\f328";
}

.mdi-guitar-pick-outline::before {
  content: "\f329";
}

.mdi-hand-pointing-right::before {
  content: "\f32a";
}

.mdi-hanger::before {
  content: "\f32b";
}

.mdi-hangouts::before {
  content: "\f32c";
}

.mdi-harddisk::before {
  content: "\f32d";
}

.mdi-headphones::before {
  content: "\f32e";
}

.mdi-headphones-box::before {
  content: "\f32f";
}

.mdi-headphones-settings::before {
  content: "\f330";
}

.mdi-headset::before {
  content: "\f331";
}

.mdi-headset-dock::before {
  content: "\f332";
}

.mdi-headset-off::before {
  content: "\f333";
}

.mdi-heart::before {
  content: "\f334";
}

.mdi-heart-box::before {
  content: "\f335";
}

.mdi-heart-box-outline::before {
  content: "\f336";
}

.mdi-heart-broken::before {
  content: "\f337";
}

.mdi-heart-outline::before {
  content: "\f338";
}

.mdi-help::before {
  content: "\f339";
}

.mdi-help-circle::before {
  content: "\f33a";
}

.mdi-hexagon::before {
  content: "\f33b";
}

.mdi-hexagon-outline::before {
  content: "\f33c";
}

.mdi-history::before {
  content: "\f33d";
}

.mdi-home::before {
  content: "\f33e";
}

.mdi-home-modern::before {
  content: "\f33f";
}

.mdi-home-variant::before {
  content: "\f340";
}

.mdi-hops::before {
  content: "\f341";
}

.mdi-hospital::before {
  content: "\f342";
}

.mdi-hospital-building::before {
  content: "\f343";
}

.mdi-hospital-marker::before {
  content: "\f344";
}

.mdi-hotel::before {
  content: "\f345";
}

.mdi-houzz::before {
  content: "\f346";
}

.mdi-houzz-box::before {
  content: "\f347";
}

.mdi-human::before {
  content: "\f348";
}

.mdi-human-child::before {
  content: "\f349";
}

.mdi-human-male-female::before {
  content: "\f34a";
}

.mdi-image-album::before {
  content: "\f34b";
}

.mdi-image-area::before {
  content: "\f34c";
}

.mdi-image-area-close::before {
  content: "\f34d";
}

.mdi-image-broken::before {
  content: "\f34e";
}

.mdi-image-filter::before {
  content: "\f34f";
}

.mdi-image-filter-black-white::before {
  content: "\f350";
}

.mdi-image-filter-center-focus::before {
  content: "\f351";
}

.mdi-image-filter-drama::before {
  content: "\f352";
}

.mdi-image-filter-frames::before {
  content: "\f353";
}

.mdi-image-filter-hdr::before {
  content: "\f354";
}

.mdi-image-filter-none::before {
  content: "\f355";
}

.mdi-image-filter-tilt-shift::before {
  content: "\f356";
}

.mdi-image-filter-vintage::before {
  content: "\f357";
}

.mdi-inbox::before {
  content: "\f358";
}

.mdi-information::before {
  content: "\f359";
}

.mdi-information-outline::before {
  content: "\f35a";
}

.mdi-instagram::before {
  content: "\f35b";
}

.mdi-instapaper::before {
  content: "\f35c";
}

.mdi-internet-explorer::before {
  content: "\f35d";
}

.mdi-invert-colors::before {
  content: "\f35e";
}

.mdi-jira::before {
  content: "\f35f";
}

.mdi-keg::before {
  content: "\f360";
}

.mdi-key::before {
  content: "\f361";
}

.mdi-key-change::before {
  content: "\f362";
}

.mdi-key-minus::before {
  content: "\f363";
}

.mdi-key-plus::before {
  content: "\f364";
}

.mdi-key-remove::before {
  content: "\f365";
}

.mdi-key-variant::before {
  content: "\f366";
}

.mdi-keyboard::before {
  content: "\f367";
}

.mdi-keyboard-backspace::before {
  content: "\f368";
}

.mdi-keyboard-caps::before {
  content: "\f369";
}

.mdi-keyboard-close::before {
  content: "\f36a";
}

.mdi-keyboard-off::before {
  content: "\f36b";
}

.mdi-keyboard-return::before {
  content: "\f36c";
}

.mdi-keyboard-tab::before {
  content: "\f36d";
}

.mdi-label::before {
  content: "\f36e";
}

.mdi-label-outline::before {
  content: "\f36f";
}

.mdi-language-csharp::before {
  content: "\f370";
}

.mdi-language-css3::before {
  content: "\f371";
}

.mdi-language-html5::before {
  content: "\f372";
}

.mdi-language-javascript::before {
  content: "\f373";
}

.mdi-language-python::before {
  content: "\f374";
}

.mdi-language-python-text::before {
  content: "\f375";
}

.mdi-laptop::before {
  content: "\f376";
}

.mdi-laptop-chromebook::before {
  content: "\f377";
}

.mdi-laptop-mac::before {
  content: "\f378";
}

.mdi-laptop-windows::before {
  content: "\f379";
}

.mdi-lastfm::before {
  content: "\f37a";
}

.mdi-launch::before {
  content: "\f37b";
}

.mdi-layers::before {
  content: "\f37c";
}

.mdi-layers-off::before {
  content: "\f37d";
}

.mdi-leaf::before {
  content: "\f37e";
}

.mdi-library::before {
  content: "\f37f";
}

.mdi-library-books::before {
  content: "\f380";
}

.mdi-library-music::before {
  content: "\f381";
}

.mdi-library-plus::before {
  content: "\f382";
}

.mdi-lightbulb::before {
  content: "\f383";
}

.mdi-lightbulb-outline::before {
  content: "\f384";
}

.mdi-link::before {
  content: "\f385";
}

.mdi-link-variant::before {
  content: "\f386";
}

.mdi-linkedin::before {
  content: "\f387";
}

.mdi-linkedin-box::before {
  content: "\f388";
}

.mdi-linux::before {
  content: "\f389";
}

.mdi-lock::before {
  content: "\f38a";
}

.mdi-lock-open::before {
  content: "\f38b";
}

.mdi-lock-open-outline::before {
  content: "\f38c";
}

.mdi-lock-outline::before {
  content: "\f38d";
}

.mdi-login::before {
  content: "\f38e";
}

.mdi-logout::before {
  content: "\f38f";
}

.mdi-looks::before {
  content: "\f390";
}

.mdi-loupe::before {
  content: "\f391";
}

.mdi-lumx::before {
  content: "\f392";
}

.mdi-magnify::before {
  content: "\f393";
}

.mdi-magnify-minus::before {
  content: "\f394";
}

.mdi-magnify-plus::before {
  content: "\f395";
}

.mdi-map::before {
  content: "\f396";
}

.mdi-map-marker::before {
  content: "\f397";
}

.mdi-map-marker-circle::before {
  content: "\f398";
}

.mdi-map-marker-multiple::before {
  content: "\f399";
}

.mdi-map-marker-off::before {
  content: "\f39a";
}

.mdi-map-marker-radius::before {
  content: "\f39b";
}

.mdi-margin::before {
  content: "\f39c";
}

.mdi-markdown::before {
  content: "\f39d";
}

.mdi-marker-check::before {
  content: "\f39e";
}

.mdi-martini::before {
  content: "\f39f";
}

.mdi-material-ui::before {
  content: "\f3a0";
}

.mdi-math-compass::before {
  content: "\f3a1";
}

.mdi-memory::before {
  content: "\f3a2";
}

.mdi-menu::before {
  content: "\f3a3";
}

.mdi-menu-down::before {
  content: "\f3a4";
}

.mdi-menu-left::before {
  content: "\f3a5";
}

.mdi-menu-right::before {
  content: "\f3a6";
}

.mdi-menu-up::before {
  content: "\f3a7";
}

.mdi-message::before {
  content: "\f3a8";
}

.mdi-message-alert::before {
  content: "\f3a9";
}

.mdi-message-draw::before {
  content: "\f3aa";
}

.mdi-message-image::before {
  content: "\f3ab";
}

.mdi-message-processing::before {
  content: "\f3ac";
}

.mdi-message-reply::before {
  content: "\f3ad";
}

.mdi-message-text::before {
  content: "\f3ae";
}

.mdi-message-text-outline::before {
  content: "\f3af";
}

.mdi-message-video::before {
  content: "\f3b0";
}

.mdi-microphone::before {
  content: "\f3b1";
}

.mdi-microphone-off::before {
  content: "\f3b2";
}

.mdi-microphone-outline::before {
  content: "\f3b3";
}

.mdi-microphone-settings::before {
  content: "\f3b4";
}

.mdi-microphone-variant::before {
  content: "\f3b5";
}

.mdi-microphone-variant-off::before {
  content: "\f3b6";
}

.mdi-minus::before {
  content: "\f3b7";
}

.mdi-minus-box::before {
  content: "\f3b8";
}

.mdi-minus-circle::before {
  content: "\f3b9";
}

.mdi-minus-circle-outline::before {
  content: "\f3ba";
}

.mdi-minus-network::before {
  content: "\f3bb";
}

.mdi-monitor::before {
  content: "\f3bc";
}

.mdi-monitor-multiple::before {
  content: "\f3bd";
}

.mdi-more::before {
  content: "\f3be";
}

.mdi-motorbike::before {
  content: "\f3bf";
}

.mdi-mouse::before {
  content: "\f3c0";
}

.mdi-mouse-off::before {
  content: "\f3c1";
}

.mdi-mouse-variant::before {
  content: "\f3c2";
}

.mdi-mouse-variant-off::before {
  content: "\f3c3";
}

.mdi-movie::before {
  content: "\f3c4";
}

.mdi-multiplication::before {
  content: "\f3c5";
}

.mdi-multiplication-box::before {
  content: "\f3c6";
}

.mdi-music-box::before {
  content: "\f3c7";
}

.mdi-music-box-outline::before {
  content: "\f3c8";
}

.mdi-music-circle::before {
  content: "\f3c9";
}

.mdi-music-note::before {
  content: "\f3ca";
}

.mdi-music-note-eighth::before {
  content: "\f3cb";
}

.mdi-music-note-half::before {
  content: "\f3cc";
}

.mdi-music-note-off::before {
  content: "\f3cd";
}

.mdi-music-note-quarter::before {
  content: "\f3ce";
}

.mdi-music-note-sixteenth::before {
  content: "\f3cf";
}

.mdi-music-note-whole::before {
  content: "\f3d0";
}

.mdi-nature::before {
  content: "\f3d1";
}

.mdi-nature-people::before {
  content: "\f3d2";
}

.mdi-navigation::before {
  content: "\f3d3";
}

.mdi-needle::before {
  content: "\f3d4";
}

.mdi-nest-protect::before {
  content: "\f3d5";
}

.mdi-nest-thermostat::before {
  content: "\f3d6";
}

.mdi-newspaper::before {
  content: "\f3d7";
}

.mdi-nfc::before {
  content: "\f3d8";
}

.mdi-nfc-tap::before {
  content: "\f3d9";
}

.mdi-nfc-variant::before {
  content: "\f3da";
}

.mdi-numeric::before {
  content: "\f3db";
}

.mdi-numeric-0-box::before {
  content: "\f3dc";
}

.mdi-numeric-0-box-multiple-outline::before {
  content: "\f3dd";
}

.mdi-numeric-0-box-outline::before {
  content: "\f3de";
}

.mdi-numeric-1-box::before {
  content: "\f3df";
}

.mdi-numeric-1-box-multiple-outline::before {
  content: "\f3e0";
}

.mdi-numeric-1-box-outline::before {
  content: "\f3e1";
}

.mdi-numeric-2-box::before {
  content: "\f3e2";
}

.mdi-numeric-2-box-multiple-outline::before {
  content: "\f3e3";
}

.mdi-numeric-2-box-outline::before {
  content: "\f3e4";
}

.mdi-numeric-3-box::before {
  content: "\f3e5";
}

.mdi-numeric-3-box-multiple-outline::before {
  content: "\f3e6";
}

.mdi-numeric-3-box-outline::before {
  content: "\f3e7";
}

.mdi-numeric-4-box::before {
  content: "\f3e8";
}

.mdi-numeric-4-box-multiple-outline::before {
  content: "\f3e9";
}

.mdi-numeric-4-box-outline::before {
  content: "\f3ea";
}

.mdi-numeric-5-box::before {
  content: "\f3eb";
}

.mdi-numeric-5-box-multiple-outline::before {
  content: "\f3ec";
}

.mdi-numeric-5-box-outline::before {
  content: "\f3ed";
}

.mdi-numeric-6-box::before {
  content: "\f3ee";
}

.mdi-numeric-6-box-multiple-outline::before {
  content: "\f3ef";
}

.mdi-numeric-6-box-outline::before {
  content: "\f3f0";
}

.mdi-numeric-7-box::before {
  content: "\f3f1";
}

.mdi-numeric-7-box-multiple-outline::before {
  content: "\f3f2";
}

.mdi-numeric-7-box-outline::before {
  content: "\f3f3";
}

.mdi-numeric-8-box::before {
  content: "\f3f4";
}

.mdi-numeric-8-box-multiple-outline::before {
  content: "\f3f5";
}

.mdi-numeric-8-box-outline::before {
  content: "\f3f6";
}

.mdi-numeric-9-box::before {
  content: "\f3f7";
}

.mdi-numeric-9-box-multiple-outline::before {
  content: "\f3f8";
}

.mdi-numeric-9-box-outline::before {
  content: "\f3f9";
}

.mdi-numeric-9-plus-box::before {
  content: "\f3fa";
}

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\f3fb";
}

.mdi-numeric-9-plus-box-outline::before {
  content: "\f3fc";
}

.mdi-nutriton::before {
  content: "\f3fd";
}

.mdi-office::before {
  content: "\f3fe";
}

.mdi-oil::before {
  content: "\f3ff";
}

.mdi-omega::before {
  content: "\f400";
}

.mdi-onedrive::before {
  content: "\f401";
}

.mdi-open-in-app::before {
  content: "\f402";
}

.mdi-open-in-new::before {
  content: "\f403";
}

.mdi-ornament::before {
  content: "\f404";
}

.mdi-ornament-variant::before {
  content: "\f405";
}

.mdi-outbox::before {
  content: "\f406";
}

.mdi-package::before {
  content: "\f407";
}

.mdi-package-down::before {
  content: "\f408";
}

.mdi-package-up::before {
  content: "\f409";
}

.mdi-package-variant::before {
  content: "\f40a";
}

.mdi-package-variant-closed::before {
  content: "\f40b";
}

.mdi-palette::before {
  content: "\f40c";
}

.mdi-palette-advanced::before {
  content: "\f40d";
}

.mdi-panda::before {
  content: "\f40e";
}

.mdi-pandora::before {
  content: "\f40f";
}

.mdi-panorama::before {
  content: "\f410";
}

.mdi-panorama-fisheye::before {
  content: "\f411";
}

.mdi-panorama-horizontal::before {
  content: "\f412";
}

.mdi-panorama-vertical::before {
  content: "\f413";
}

.mdi-panorama-wide-angle::before {
  content: "\f414";
}

.mdi-paper-cut-vertical::before {
  content: "\f415";
}

.mdi-paperclip::before {
  content: "\f416";
}

.mdi-parking::before {
  content: "\f417";
}

.mdi-pause::before {
  content: "\f418";
}

.mdi-pause-circle::before {
  content: "\f419";
}

.mdi-pause-circle-outline::before {
  content: "\f41a";
}

.mdi-pause-octagon::before {
  content: "\f41b";
}

.mdi-pause-octagon-outline::before {
  content: "\f41c";
}

.mdi-pen::before {
  content: "\f41d";
}

.mdi-pencil::before {
  content: "\f41e";
}

.mdi-pencil-box::before {
  content: "\f41f";
}

.mdi-pencil-box-outline::before {
  content: "\f420";
}

.mdi-pharmacy::before {
  content: "\f421";
}

.mdi-phone::before {
  content: "\f422";
}

.mdi-phone-bluetooth::before {
  content: "\f423";
}

.mdi-phone-forward::before {
  content: "\f424";
}

.mdi-phone-hangup::before {
  content: "\f425";
}

.mdi-phone-in-talk::before {
  content: "\f426";
}

.mdi-phone-locked::before {
  content: "\f427";
}

.mdi-phone-missed::before {
  content: "\f428";
}

.mdi-phone-paused::before {
  content: "\f429";
}

.mdi-phone-settings::before {
  content: "\f42a";
}

.mdi-pig::before {
  content: "\f42b";
}

.mdi-pill::before {
  content: "\f42c";
}

.mdi-pin::before {
  content: "\f42d";
}

.mdi-pin-off::before {
  content: "\f42e";
}

.mdi-pine-tree::before {
  content: "\f42f";
}

.mdi-pine-tree-box::before {
  content: "\f430";
}

.mdi-pinterest::before {
  content: "\f431";
}

.mdi-pinterest-box::before {
  content: "\f432";
}

.mdi-pizza::before {
  content: "\f433";
}

.mdi-play::before {
  content: "\f434";
}

.mdi-play-box-outline::before {
  content: "\f435";
}

.mdi-play-circle::before {
  content: "\f436";
}

.mdi-play-circle-outline::before {
  content: "\f437";
}

.mdi-playlist-minus::before {
  content: "\f438";
}

.mdi-playlist-plus::before {
  content: "\f439";
}

.mdi-plus::before {
  content: "\f43a";
}

.mdi-plus-box::before {
  content: "\f43b";
}

.mdi-plus-circle::before {
  content: "\f43c";
}

.mdi-plus-circle-outline::before {
  content: "\f43d";
}

.mdi-plus-network::before {
  content: "\f43e";
}

.mdi-plus-one::before {
  content: "\f43f";
}

.mdi-pocket::before {
  content: "\f440";
}

.mdi-poll::before {
  content: "\f441";
}

.mdi-poll-box::before {
  content: "\f442";
}

.mdi-polymer::before {
  content: "\f443";
}

.mdi-popcorn::before {
  content: "\f444";
}

.mdi-pound::before {
  content: "\f445";
}

.mdi-pound-box::before {
  content: "\f446";
}

.mdi-power::before {
  content: "\f447";
}

.mdi-power-settings::before {
  content: "\f448";
}

.mdi-presentation::before {
  content: "\f449";
}

.mdi-presentation-play::before {
  content: "\f44a";
}

.mdi-printer::before {
  content: "\f44b";
}

.mdi-printer-3d::before {
  content: "\f44c";
}

.mdi-pulse::before {
  content: "\f44d";
}

.mdi-puzzle::before {
  content: "\f44e";
}

.mdi-qrcode::before {
  content: "\f44f";
}

.mdi-quadcopter::before {
  content: "\f450";
}

.mdi-quality-high::before {
  content: "\f451";
}

.mdi-quicktime::before {
  content: "\f452";
}

.mdi-radiator::before {
  content: "\f453";
}

.mdi-radioactive::before {
  content: "\f454";
}

.mdi-radiobox-blank::before {
  content: "\f455";
}

.mdi-radiobox-marked::before {
  content: "\f456";
}

.mdi-rdio::before {
  content: "\f457";
}

.mdi-read::before {
  content: "\f458";
}

.mdi-readability::before {
  content: "\f459";
}

.mdi-receipt::before {
  content: "\f45a";
}

.mdi-recycle::before {
  content: "\f45b";
}

.mdi-redo::before {
  content: "\f45c";
}

.mdi-redo-variant::before {
  content: "\f45d";
}

.mdi-refresh::before {
  content: "\f45e";
}

.mdi-relative-scale::before {
  content: "\f45f";
}

.mdi-reload::before {
  content: "\f460";
}

.mdi-remote::before {
  content: "\f461";
}

.mdi-rename-box::before {
  content: "\f462";
}

.mdi-repeat::before {
  content: "\f463";
}

.mdi-repeat-off::before {
  content: "\f464";
}

.mdi-repeat-once::before {
  content: "\f465";
}

.mdi-replay::before {
  content: "\f466";
}

.mdi-reply::before {
  content: "\f467";
}

.mdi-reply-all::before {
  content: "\f468";
}

.mdi-reproduction::before {
  content: "\f469";
}

.mdi-resize-bottom-right::before {
  content: "\f46a";
}

.mdi-responsive::before {
  content: "\f46b";
}

.mdi-rewind::before {
  content: "\f46c";
}

.mdi-ribbon::before {
  content: "\f46d";
}

.mdi-rocket::before {
  content: "\f46e";
}

.mdi-rotate-3d::before {
  content: "\f46f";
}

.mdi-rotate-left::before {
  content: "\f470";
}

.mdi-rotate-left-variant::before {
  content: "\f471";
}

.mdi-rotate-right::before {
  content: "\f472";
}

.mdi-rotate-right-variant::before {
  content: "\f473";
}

.mdi-routes::before {
  content: "\f474";
}

.mdi-rss::before {
  content: "\f475";
}

.mdi-rss-box::before {
  content: "\f476";
}

.mdi-ruler::before {
  content: "\f477";
}

.mdi-run::before {
  content: "\f478";
}

.mdi-satellite::before {
  content: "\f479";
}

.mdi-satellite-variant::before {
  content: "\f47a";
}

.mdi-scale::before {
  content: "\f47b";
}

.mdi-scale-bathroom::before {
  content: "\f47c";
}

.mdi-school::before {
  content: "\f47d";
}

.mdi-screen-rotation::before {
  content: "\f47e";
}

.mdi-screen-rotation-lock::before {
  content: "\f47f";
}

.mdi-script::before {
  content: "\f480";
}

.mdi-sd::before {
  content: "\f481";
}

.mdi-security::before {
  content: "\f482";
}

.mdi-security-network::before {
  content: "\f483";
}

.mdi-select::before {
  content: "\f484";
}

.mdi-select-all::before {
  content: "\f485";
}

.mdi-select-inverse::before {
  content: "\f486";
}

.mdi-select-off::before {
  content: "\f487";
}

.mdi-send::before {
  content: "\f488";
}

.mdi-server::before {
  content: "\f489";
}

.mdi-server-minus::before {
  content: "\f48a";
}

.mdi-server-network::before {
  content: "\f48b";
}

.mdi-server-network-off::before {
  content: "\f48c";
}

.mdi-server-off::before {
  content: "\f48d";
}

.mdi-server-plus::before {
  content: "\f48e";
}

.mdi-server-remove::before {
  content: "\f48f";
}

.mdi-server-security::before {
  content: "\f490";
}

.mdi-settings::before {
  content: "\f491";
}

.mdi-settings-box::before {
  content: "\f492";
}

.mdi-shape-plus::before {
  content: "\f493";
}

.mdi-share::before {
  content: "\f494";
}

.mdi-share-variant::before {
  content: "\f495";
}

.mdi-shopping::before {
  content: "\f496";
}

.mdi-shopping-music::before {
  content: "\f497";
}

.mdi-shuffle::before {
  content: "\f498";
}

.mdi-sigma::before {
  content: "\f499";
}

.mdi-sign-caution::before {
  content: "\f49a";
}

.mdi-silverware::before {
  content: "\f49b";
}

.mdi-silverware-fork::before {
  content: "\f49c";
}

.mdi-silverware-spoon::before {
  content: "\f49d";
}

.mdi-silverware-variant::before {
  content: "\f49e";
}

.mdi-sim-alert::before {
  content: "\f49f";
}

.mdi-skip-next::before {
  content: "\f4a0";
}

.mdi-skip-previous::before {
  content: "\f4a1";
}

.mdi-snowman::before {
  content: "\f4a2";
}

.mdi-sort::before {
  content: "\f4a3";
}

.mdi-sort-alphabetical::before {
  content: "\f4a4";
}

.mdi-sort-ascending::before {
  content: "\f4a5";
}

.mdi-sort-descending::before {
  content: "\f4a6";
}

.mdi-sort-numeric::before {
  content: "\f4a7";
}

.mdi-sort-variant::before {
  content: "\f4a8";
}

.mdi-soundcloud::before {
  content: "\f4a9";
}

.mdi-source-fork::before {
  content: "\f4aa";
}

.mdi-source-pull::before {
  content: "\f4ab";
}

.mdi-speaker::before {
  content: "\f4ac";
}

.mdi-speaker-off::before {
  content: "\f4ad";
}

.mdi-speedometer::before {
  content: "\f4ae";
}

.mdi-spellcheck::before {
  content: "\f4af";
}

.mdi-spotify::before {
  content: "\f4b0";
}

.mdi-spotlight::before {
  content: "\f4b1";
}

.mdi-spotlight-beam::before {
  content: "\f4b2";
}

.mdi-stackoverflow::before {
  content: "\f4b3";
}

.mdi-star::before {
  content: "\f4b4";
}

.mdi-star-circle::before {
  content: "\f4b5";
}

.mdi-star-half::before {
  content: "\f4b6";
}

.mdi-star-outline::before {
  content: "\f4b7";
}

.mdi-stocking::before {
  content: "\f4b8";
}

.mdi-stop::before {
  content: "\f4b9";
}

.mdi-store::before {
  content: "\f4ba";
}

.mdi-store-24-hour::before {
  content: "\f4bb";
}

.mdi-stove::before {
  content: "\f4bc";
}

.mdi-subway::before {
  content: "\f4bd";
}

.mdi-swap-horizontal::before {
  content: "\f4be";
}

.mdi-swap-vertical::before {
  content: "\f4bf";
}

.mdi-swim::before {
  content: "\f4c0";
}

.mdi-sword::before {
  content: "\f4c1";
}

.mdi-sync::before {
  content: "\f4c2";
}

.mdi-sync-alert::before {
  content: "\f4c3";
}

.mdi-sync-off::before {
  content: "\f4c4";
}

.mdi-tab::before {
  content: "\f4c5";
}

.mdi-tab-unselected::before {
  content: "\f4c6";
}

.mdi-table::before {
  content: "\f4c7";
}

.mdi-table-column-plus-after::before {
  content: "\f4c8";
}

.mdi-table-column-plus-before::before {
  content: "\f4c9";
}

.mdi-table-column-remove::before {
  content: "\f4ca";
}

.mdi-table-column-width::before {
  content: "\f4cb";
}

.mdi-table-edit::before {
  content: "\f4cc";
}

.mdi-table-large::before {
  content: "\f4cd";
}

.mdi-table-row-height::before {
  content: "\f4ce";
}

.mdi-table-row-plus-after::before {
  content: "\f4cf";
}

.mdi-table-row-plus-before::before {
  content: "\f4d0";
}

.mdi-table-row-remove::before {
  content: "\f4d1";
}

.mdi-tablet::before {
  content: "\f4d2";
}

.mdi-tablet-android::before {
  content: "\f4d3";
}

.mdi-tablet-ipad::before {
  content: "\f4d4";
}

.mdi-tag::before {
  content: "\f4d5";
}

.mdi-tag-faces::before {
  content: "\f4d6";
}

.mdi-tag-outline::before {
  content: "\f4d7";
}

.mdi-tag-text-outline::before {
  content: "\f4d8";
}

.mdi-taxi::before {
  content: "\f4d9";
}

.mdi-television::before {
  content: "\f4da";
}

.mdi-television-guide::before {
  content: "\f4db";
}

.mdi-temperature-celsius::before {
  content: "\f4dc";
}

.mdi-temperature-fahrenheit::before {
  content: "\f4dd";
}

.mdi-temperature-kelvin::before {
  content: "\f4de";
}

.mdi-tent::before {
  content: "\f4df";
}

.mdi-terrain::before {
  content: "\f4e0";
}

.mdi-text-to-speech::before {
  content: "\f4e1";
}

.mdi-text-to-speech-off::before {
  content: "\f4e2";
}

.mdi-texture::before {
  content: "\f4e3";
}

.mdi-theater::before {
  content: "\f4e4";
}

.mdi-theme-light-dark::before {
  content: "\f4e5";
}

.mdi-thermometer::before {
  content: "\f4e6";
}

.mdi-thermometer-lines::before {
  content: "\f4e7";
}

.mdi-thumb-down::before {
  content: "\f4e8";
}

.mdi-thumb-down-outline::before {
  content: "\f4e9";
}

.mdi-thumb-up::before {
  content: "\f4ea";
}

.mdi-thumb-up-outline::before {
  content: "\f4eb";
}

.mdi-thumbs-up-down::before {
  content: "\f4ec";
}

.mdi-ticket::before {
  content: "\f4ed";
}

.mdi-ticket-account::before {
  content: "\f4ee";
}

.mdi-tie::before {
  content: "\f4ef";
}

.mdi-timelapse::before {
  content: "\f4f0";
}

.mdi-timer::before {
  content: "\f4f1";
}

.mdi-timer-10::before {
  content: "\f4f2";
}

.mdi-timer-3::before {
  content: "\f4f3";
}

.mdi-timer-off::before {
  content: "\f4f4";
}

.mdi-timer-sand::before {
  content: "\f4f5";
}

.mdi-timetable::before {
  content: "\f4f6";
}

.mdi-toggle-switch::before {
  content: "\f4f7";
}

.mdi-toggle-switch-off::before {
  content: "\f4f8";
}

.mdi-tooltip::before {
  content: "\f4f9";
}

.mdi-tooltip-edit::before {
  content: "\f4fa";
}

.mdi-tooltip-image::before {
  content: "\f4fb";
}

.mdi-tooltip-outline::before {
  content: "\f4fc";
}

.mdi-tooltip-outline-plus::before {
  content: "\f4fd";
}

.mdi-tooltip-text::before {
  content: "\f4fe";
}

.mdi-tor::before {
  content: "\f4ff";
}

.mdi-traffic-light::before {
  content: "\f500";
}

.mdi-train::before {
  content: "\f501";
}

.mdi-tram::before {
  content: "\f502";
}

.mdi-transcribe::before {
  content: "\f503";
}

.mdi-transcribe-close::before {
  content: "\f504";
}

.mdi-transfer::before {
  content: "\f505";
}

.mdi-tree::before {
  content: "\f506";
}

.mdi-trello::before {
  content: "\f507";
}

.mdi-trending-down::before {
  content: "\f508";
}

.mdi-trending-neutral::before {
  content: "\f509";
}

.mdi-trending-up::before {
  content: "\f50a";
}

.mdi-trophy::before {
  content: "\f50b";
}

.mdi-trophy-award::before {
  content: "\f50c";
}

.mdi-trophy-variant::before {
  content: "\f50d";
}

.mdi-truck::before {
  content: "\f50e";
}

.mdi-tshirt-crew::before {
  content: "\f50f";
}

.mdi-tshirt-v::before {
  content: "\f510";
}

.mdi-tumblr::before {
  content: "\f511";
}

.mdi-tumblr-reblog::before {
  content: "\f512";
}

.mdi-twitch::before {
  content: "\f513";
}

.mdi-twitter::before {
  content: "\f514";
}

.mdi-twitter-box::before {
  content: "\f515";
}

.mdi-twitter-retweet::before {
  content: "\f516";
}

.mdi-ubuntu::before {
  content: "\f517";
}

.mdi-undo::before {
  content: "\f518";
}

.mdi-undo-variant::before {
  content: "\f519";
}

.mdi-unfold-less::before {
  content: "\f51a";
}

.mdi-unfold-more::before {
  content: "\f51b";
}

.mdi-untappd::before {
  content: "\f51c";
}

.mdi-upload::before {
  content: "\f51d";
}

.mdi-usb::before {
  content: "\f51e";
}

.mdi-vector-curve::before {
  content: "\f51f";
}

.mdi-vector-point::before {
  content: "\f520";
}

.mdi-vector-square::before {
  content: "\f521";
}

.mdi-verified::before {
  content: "\f522";
}

.mdi-vibrate::before {
  content: "\f523";
}

.mdi-video::before {
  content: "\f524";
}

.mdi-video-off::before {
  content: "\f525";
}

.mdi-video-switch::before {
  content: "\f526";
}

.mdi-view-agenda::before {
  content: "\f527";
}

.mdi-view-array::before {
  content: "\f528";
}

.mdi-view-carousel::before {
  content: "\f529";
}

.mdi-view-column::before {
  content: "\f52a";
}

.mdi-view-dashboard::before {
  content: "\f52b";
}

.mdi-view-day::before {
  content: "\f52c";
}

.mdi-view-headline::before {
  content: "\f52d";
}

.mdi-view-list::before {
  content: "\f52e";
}

.mdi-view-module::before {
  content: "\f52f";
}

.mdi-view-quilt::before {
  content: "\f530";
}

.mdi-view-stream::before {
  content: "\f531";
}

.mdi-view-week::before {
  content: "\f532";
}

.mdi-vimeo::before {
  content: "\f533";
}

.mdi-vk::before {
  content: "\f534";
}

.mdi-voicemail::before {
  content: "\f535";
}

.mdi-volume-high::before {
  content: "\f536";
}

.mdi-volume-low::before {
  content: "\f537";
}

.mdi-volume-medium::before {
  content: "\f538";
}

.mdi-volume-off::before {
  content: "\f539";
}

.mdi-walk::before {
  content: "\f53a";
}

.mdi-wallet::before {
  content: "\f53b";
}

.mdi-wallet-giftcard::before {
  content: "\f53c";
}

.mdi-wallet-membership::before {
  content: "\f53d";
}

.mdi-wallet-travel::before {
  content: "\f53e";
}

.mdi-watch::before {
  content: "\f53f";
}

.mdi-watch-export::before {
  content: "\f540";
}

.mdi-watch-import::before {
  content: "\f541";
}

.mdi-water::before {
  content: "\f542";
}

.mdi-water-off::before {
  content: "\f543";
}

.mdi-water-pump::before {
  content: "\f544";
}

.mdi-weather-cloudy::before {
  content: "\f545";
}

.mdi-weather-hail::before {
  content: "\f546";
}

.mdi-weather-lightning::before {
  content: "\f547";
}

.mdi-weather-night::before {
  content: "\f548";
}

.mdi-weather-partlycloudy::before {
  content: "\f549";
}

.mdi-weather-pouring::before {
  content: "\f54a";
}

.mdi-weather-rainy::before {
  content: "\f54b";
}

.mdi-weather-snowy::before {
  content: "\f54c";
}

.mdi-weather-sunny::before {
  content: "\f54d";
}

.mdi-weather-sunset::before {
  content: "\f54e";
}

.mdi-weather-sunset-down::before {
  content: "\f54f";
}

.mdi-weather-sunset-up::before {
  content: "\f550";
}

.mdi-weather-windy::before {
  content: "\f551";
}

.mdi-weather-windy-variant::before {
  content: "\f552";
}

.mdi-web::before {
  content: "\f553";
}

.mdi-webcam::before {
  content: "\f554";
}

.mdi-weight::before {
  content: "\f555";
}

.mdi-weight-kilogram::before {
  content: "\f556";
}

.mdi-whatsapp::before {
  content: "\f557";
}

.mdi-wheelchair-accessibility::before {
  content: "\f558";
}

.mdi-white-balance-auto::before {
  content: "\f559";
}

.mdi-white-balance-incandescent::before {
  content: "\f55a";
}

.mdi-white-balance-irradescent::before {
  content: "\f55b";
}

.mdi-white-balance-sunny::before {
  content: "\f55c";
}

.mdi-wifi::before {
  content: "\f55d";
}

.mdi-wikipedia::before {
  content: "\f55e";
}

.mdi-window-close::before {
  content: "\f55f";
}

.mdi-window-closed::before {
  content: "\f560";
}

.mdi-window-maximize::before {
  content: "\f561";
}

.mdi-window-minimize::before {
  content: "\f562";
}

.mdi-window-open::before {
  content: "\f563";
}

.mdi-window-restore::before {
  content: "\f564";
}

.mdi-windows::before {
  content: "\f565";
}

.mdi-wordpress::before {
  content: "\f566";
}

.mdi-xbox::before {
  content: "\f567";
}

.mdi-xbox-controller::before {
  content: "\f568";
}

.mdi-xbox-controller-off::before {
  content: "\f569";
}

.mdi-xda::before {
  content: "\f56a";
}

.mdi-xml::before {
  content: "\f56b";
}

.mdi-yeast::before {
  content: "\f56c";
}

.mdi-youtube-play::before {
  content: "\f56d";
}

.mdi-zip-box::before {
  content: "\f56e";
}
