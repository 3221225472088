
// Body
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #009FDA;
$brand-info: #003591;
$brand-success: #36842D;
$brand-warning: #cbb956;
$brand-danger: #C4262E;

$bg-blue: #E5F5FB;
$bg-grey: #F2F2F2;
$bg-blue-dark: #0F204B;
$light-blue: #99D9F0;

$font-base: #333333;

$grid-gutter-width: 30px;
// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop;

// Large screen / wide desktop
$container-large-desktop:      (940px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop;


$btn-primary-border: #fff;


$jumbotron-padding:              20px;
$jumbotron-color:                inherit !default;
$jumbotron-bg:                   $bg-blue;
$jumbotron-heading-color:        inherit !default;
$jumbotron-font-size:            16px;
$jumbotron-heading-font-size:    22px;


$navbar-border-radius: 0px;








$alert-danger-bg:             $brand-danger;
$alert-danger-text:           lighten($brand-danger, 90%);
$alert-danger-border:         $brand-danger;


$alert-success-bg:             $brand-success;
$alert-success-text:           lighten($brand-success, 90%);
$alert-success-border:         $brand-success;