.table-select-vessels{
  display: table;
  width: 100%;
  .table-head, .table-body{
    display: table-row-group;
  }
  .table-row{
    display: table-row;
    width: 100%;
  }
  .table-cell{
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid $brand-primary;
    padding: 6px;
  }


  .table-head{
    font-size: 14px;
    font-family: 'avenirdemi';
    .vessel-selected{
      label{
        background: #fff;
        border-radius: 4px;
        padding: 4px 6px;
        color: $brand-primary;
        font-weight: 100;
      }
      input{
        margin-right: 4px;
      }
    }
    @media(min-width: $screen-sm-min){
      .vessel-imo-placeholder{
        display: none;
      }
    }
  }
  .vessel-mrv{
    text-align: center;
  }
  .table-body{
    font-size: 16px;
  }
  .table-body .vessel-mrv{
    color: $brand-primary;
    vertical-align: middle;
    .fa{
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.anonymous .vessel-mrv{
    display: none;
  }

}