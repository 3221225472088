body{
  position: relative;
}
hr{
  border-color: $brand-primary;
  max-width: 240px;
  text-align: center;
}

.btn{
  font-family: 'avenirdemi';
  font-size: 16px;

  padding: 17px 47px;

  @media (max-width: $screen-xs-max) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.container .jumbotron{
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  h2{
    color: $brand-info;
  }
  .btn{
    padding-left: 9px;
    padding-right: 9px;
  }
}
.cookies {

  display: none;
  padding-top: 40px;
  padding-bottom: 20px;

  font-size: 14px;

  .border {
    border: #bce5f4 1px solid;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
footer{
  border-top: 14px solid $bg-blue;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $bg-grey;


  font-size: 14px;

  ul{
    margin-top: 0;
    margin-left: 0;
    padding-left: 0;
    li{
      list-style: none;
      margin-right: 40px;
    }
  }

  .col-md-8{

  }
}

.btn-full{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.btn-form{
  padding-top: 13px;
  padding-bottom: 13px;
  margin-top: 29px;
}

.mt-20{
  margin-top: 20px;
}
.mt-40{
  margin-top: 40px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-40{
  margin-bottom: 40px;
}

.mt-120{
  margin-top: 120px;
}