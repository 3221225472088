body{
  font-family: 'avenirregular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
  color: $font-base;
}
h1{

  font-size: 22px;
  @media (min-width: $screen-sm-min) {
    font-size: 36px;
  }
  color: $brand-primary;
  font-family: 'broaderviewmedium';

  text-transform: uppercase;
}
h2{
  font-size: 20px;
  @media (min-width: $screen-sm-min) {
    font-size: 22px;
  }
  font-family: 'avenirdemi';
}
h3{
  font-size: 16px;
  font-family: 'avenirdemi';
  color: $brand-info;
}