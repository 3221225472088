$pager-max-width: 970px;
$nonpager-min-width: $pager-max-width + 1px;

.vessels{
  .row{
    width: 940px;

    @media(min-width: $nonpager-min-width){

      margin-left: 0;
      margin-right: 0;
      transform: translateX(-0%) !important;
    }
    @media(max-width: $pager-max-width){
      transition: transform .5s ease;
      transform: translateX(-0%);
    }
  }
  @media(max-width: $pager-max-width){
    width: 205px;
    margin-left: auto;
    margin-right: auto;
  }

}
.vessel-card{

  margin-bottom: 25px;
  width: 25%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;

  transition: opacity .5s ease;

  .vessel-card-inner{
    background: #fff;
    border-radius: 4px;
    padding: 10px 10px 40px 10px;


    .vessel-img{
      width: 185px;
      height: 139px;
      overflow: hidden;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;


    }

    .vessel-title{
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 16px;
      font-family: 'avenirdemi';
      text-transform: uppercase;
    }
    .vessel-imo, .vessel-type{
      font-size: 14px;
      display: block;
    }
  }

  @media(max-width: $pager-max-width){
    &:not(.active) .vessel-card-inner{

      opacity: .6;
    }
  }
}

.vessel-pager{
  display: none;
  @media(max-width: $pager-max-width){
    display: block;
  }
  ol{
    padding-left: 0;
    li{
      display: inline-block;
      list-style: none;
      width: 13px;
      height: 13px;
      border-radius: 14px;
      border: 1px solid $brand-primary;

      background: #fff;
      cursor: pointer;

      transition: background .5s ease;

      &.active{
        background: $brand-primary;
      }

    }
  }
}